import { createTheme } from "@mui/material/styles";

const themeLookup = {
  default: createTheme({
    palette: {
      primary: {
        contrastText: "#FFFFFF",
        main: "#3F51B5",
        //main: "#1b5e20",
      },
      secondary: {
        contrastText: "#FFFFFF",
        main: "#888888",
        //main: "#077dc2",
      },
    },
  }),
  org_VlMsxx3iajnlpdLb: createTheme({
    palette: {
      primary: {
        contrastText: "#FFFFFF",
        main: "#489C9D",
        //main: "#489c9d",
      },
      secondary: {
        contrastText: "#FFFFFF",
        main: "#DDB300",
        //main: "#ddb300",
      },
    },
  }),
  org_YmvfrBq1DyFLiB4m: createTheme({
    palette: {
      primary: {
        contrastText: "#FFFFFF",
        main: "#2E70CC",
        //main: "#0082d1",
      },
      secondary: {
        contrastText: "#FFFFFF",
        main: "#E26D26",
        //main: "#e26d26",
      },
    },
  }),
  org_foYuMPJHfrqvKafX: createTheme({
    palette: {
      primary: {
        contrastText: "#FFFFFF",
        main: "#5097FB",
        //main: "#007dc3",
      },
      secondary: {
        contrastText: "#FFFFFF",
        main: "#4F7435",
        //main: "#4f7435",
      },
    },
  }),
};

function getTheme(organization_id) {
  return themeLookup[organization_id] ?? themeLookup.default;
}

export default getTheme;
