import { useContext } from "react";

import axios from "axios";
import { Badge, Box, Fab } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { convertToFromUTC } from "./tools";
import NoteIcon from "@mui/icons-material/Note";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";

import AuthContext from "./AuthContext";
import ChatContext from "./ChatContext";

function displayNotification(isCollector, request) {
  return (
    !isCollector &&
    request.CreatedAt.localeCompare(convertToFromUTC(new Date().setMinutes(new Date().getMinutes() - 10))) < 1 &&
    request?.Status === "Pending"
  );
}

/*
import { getTimezoneOffset } from "date-fns-tz";
import { parse, differenceInMinutes, addMilliseconds } from "date-fns";

const MINUTES_AFTER_CREATION = 10;

function displayNotification(isCollector, request) {
  let createdAt;
  try {
    createdAt = parse(
      request?.CreatedAt,
      "yyyy-MM-dd'T'HH:mm:ss.SSS",
      new Date()
    );
  } catch (error) {
    createdAt = parse(request?.CreatedAt, "yyyy-MM-dd'T'HH:mm:ss", new Date());
  }
  createdAt = addMilliseconds(createdAt, -getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone));
  const now = addMilliseconds(
    new Date(),
    -getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone)
  );
  return (
    !isCollector &&
    -differenceInMinutes(createdAt, now) > MINUTES_AFTER_CREATION &&
    request?.Status === "Pending"
  );
}
*/

function ChatButton({
  active = true,
  conditionfn = (f) => true,
  isCollector = false,
  request,
  setNotesOpen = (f) => f,
}) {
  const { accessToken, isLoggedIn } = useContext(AuthContext);
  const { setChatOpen } = useContext(ChatContext);
  const queryClient = useQueryClient();
  const notificationMutation = useMutation(async ({ token, guid }) => {
    console.debug(request);
    try {
      const response = await axios.post(
        `https://ahc.wolfeone.com/api/requesters/requests/${guid}/notify?escalate=True`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Escalated to supervisor");
      return response.data;
    } catch (error) {
      if (isLoggedIn) {
        toast.error("Error posting notification");
      }
    }
  });

  const recipient = isCollector ? "REQUESTER" : "COLLECTOR";
  const notifyFab = (
    <Fab
      aria-label="add"
      color="primary"
      onClick={() => {
        notificationMutation.mutate({
          token: accessToken,
          guid: request?.CollectionRequestGUID,
        });
      }}
      sx={{ marginRight: 1, marginTop: 1 }}
      variant="extended"
    >
      <NotificationImportantIcon />
      {"Notify Supervisor"}
    </Fab>
  );
  const notesFab = (
    <Fab
      aria-label="add"
      color="primary"
      onClick={() => setNotesOpen(true)}
      sx={{ marginRight: 1, marginTop: 1 }}
      variant="extended"
    >
      <NoteIcon
        sx={{ marginRight: 1 }}
      />
      {"Notes"}
    </Fab>
  );
  const chatFab = (
    <Fab
      aria-label="add"
      color="primary"
      onClick={() => {
        setChatOpen(true);
        queryClient.invalidateQueries(["messages"]);
        if (isCollector) {
          queryClient.invalidateQueries(["collectorRequests"]);
        } else {
          queryClient.invalidateQueries(["requests"]);
        }
      }}
      sx={{ marginRight: 1, marginTop: 1 }}
      variant="extended"
    >
      <ChatIcon
        sx={{ marginRight: 1 }}
      />
      {active ? `CHAT WITH ${recipient}` : "VIEW CHAT"}
    </Fab>
  );
  const BoxWrapper = ({ children }) => {
    return (
      <Box
        sx={{
          bgcolor: "transparent",
          bottom: 10,
          left: "auto",
          margin: 0,
          right: 20,
          top: "auto",
        }}
      >
        {children}
      </Box>
    );
  };

  return request?.UnreadMessageCount > 0 ? (
    <BoxWrapper>
      {displayNotification(isCollector, request) ? notifyFab : null}
      <Badge badgeContent={request?.UnreadMessageCount} color="secondary" overlap="circular">
        {conditionfn() ? chatFab : null}
      </Badge>
      {isCollector ? notesFab : null}
    </BoxWrapper>
  ) : (
    <BoxWrapper>
      {displayNotification(isCollector, request) ? notifyFab : null}
      {conditionfn() ? chatFab : null}
      {isCollector ? notesFab : null}
    </BoxWrapper>
  );
}

export default ChatButton;
