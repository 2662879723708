import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import {
  Button,
  FormControlLabel,
  Switch,
  RadioGroup,
  Tooltip,
  Radio,
  Collapse,
} from "@mui/material";

function CollectionTypeStep({
  handleNext = (f) => f,
  handleBack = (f) => f,
  useDOT = true,
  setUseDOT = (f) => f,
  testAlcohol = true,
  setTestAlcohol = (f) => f,
  modality = "fmcsa",
  setModality = (f) => f,
}) {
  return (
    <>
      <StepLabel>Collection Type</StepLabel>
      <StepContent>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={testAlcohol}
                  onChange={(e) => {
                    setTestAlcohol(e.target.checked);
                  }}
                />
              }
              label="Alcohol Test"
            />
            <RadioGroup
              row
              aria-label="dot vs non-dot"
              defaultValue="dot"
              value={useDOT ? "dot" : "non-dot"}
              onChange={(e) => {
                setUseDOT(e.target.value === "dot");
              }}
            >
              <FormControlLabel
                value="non-dot"
                control={<Radio />}
                label="Non-DOT Test"
              />
              <FormControlLabel value="dot" control={<Radio />} label="DOT Test" />
            </RadioGroup>
            <Collapse in={useDOT}>
              <RadioGroup
                aria-label="dot type"
                defaultValue="fmcsa"
                value={modality}
                onChange={(e) => {
                  setModality(e.target.value);
                }}
              >
                <Tooltip title="Federal Motor Carrier Safety Administration">
                  <FormControlLabel
                    value="fmcsa"
                    control={<Radio />}
                    label="FMCSA"
                  />
                </Tooltip>
                <Tooltip title="Federal Transit Administration">
                  <FormControlLabel
                    value="fta"
                    control={<Radio />}
                    label="FTA"
                  />
                </Tooltip>
                <Tooltip title="Pipeline and Hazardous Materials Safety Administration">
                  <FormControlLabel
                    value="phmsa"
                    control={<Radio />}
                    label="PHMSA"
                  />
                </Tooltip>
                <Tooltip title="Federal Aviation Administration">
                  <FormControlLabel
                    value="faa"
                    control={<Radio />}
                    label="FAA"
                  />
                </Tooltip>
                <Tooltip
                  title="Federal Railroad Administration"
                  disableInteractive={true}
                >
                  <FormControlLabel
                    value="fra"
                    control={<Radio />}
                    label="FRA"
                  />
                </Tooltip>
              </RadioGroup>
            </Collapse>
          </FormGroup>
          <Button onClick={handleBack}>Back</Button>
          <Button onClick={handleNext}>Next</Button>
        </Box>
      </StepContent>
    </>
  );
}

export default CollectionTypeStep;
