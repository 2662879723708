import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import RequesterHome from "./RequesterHome";
import CollectorHome from "./CollectorHome";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

import AuthContext from "./AuthContext";
import getTheme from "./ThemeLookup";
import { Toaster } from "react-hot-toast";

function App() {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
    getIdTokenClaims,
  } = useAuth0();

  const [accessToken, setAccessToken] = useState();
  const [organization, setOrganization] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState();

  useEffect(() => {
    let checkAuth = async () => {
      setIsLoggedIn(isAuthenticated);
      if (!isAuthenticated && !isLoading) {
        loginWithRedirect();
      } else if (!isAuthenticated && isLoading) {
        // currently loading the auth
      } else {
        const claims = await getIdTokenClaims();
        setOrganization(claims.org_id);
        const token = await getAccessTokenSilently();
        console.debug("TOKEN:", token);
        setAccessToken(token);
      }
    };
    checkAuth();
  });

  if (isAuthenticated && !isLoading) {
    const userType = user.user_type || "requester";
    return (
      <AuthContext.Provider
        value={{
          accessToken,
          setAccessToken,
          organization,
          setOrganization,
          isLoggedIn,
          userType,
        }}
      >
        <ThemeProvider theme={getTheme(organization)}>
          <div className="App" style={{ background: "#F8F4F4" }}>
            <Toaster />
            <Box
              sx={{
                width: "100%",
                maxWidth: 640,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {userType === "requester" ? <RequesterHome /> : <CollectorHome />}
            </Box>
          </div>
        </ThemeProvider>
      </AuthContext.Provider>
    );
  }

  return <div className="App"></div>;
}

export default App;
