import { useState } from "react";

import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";

function NameAndReasonStep({
  collDate,
  dateValue,
  donorName,
  donorPhone,
  minDateTime,
  reason,
  handleNext = (f) => f,
  setCollDate = (f) => f,
  setDateValue = (f) => f,
  setDonorName = (f) => f,
  setDonorPhone = (f) => f,
  setMinDateTime = (f) => f,
  setReason = (f) => f,
}) {
  const [isInvalidDonor, setIsInvalidDonor] = useState(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

  const donorFieldChanged = (e) => {
    const donorInput = e.target;
    setDonorName(donorInput.value);
    if (donorInput.value.length > 0 && isInvalidDonor) {
      setIsInvalidDonor(false);
    }
  };

  const donorPhoneChanged = (e) => {
    const phoneInput = e.target;
    setDonorPhone(phoneInput.value);
    if (phoneInput.value.length > 0 && isInvalidPhone) {
      setIsInvalidPhone(false);
    }
  };

  const checkNextInputs = () => {
    const donorInput = document.getElementById("outlined-basic");
    const phoneInput = document.getElementById("outlined-basic-phone");
    if (donorInput.value.length <= 0) {
      setIsInvalidDonor(true);
      donorInput.focus();
      return;
    } else {
      setDonorName(donorInput.value);
    }
    if (donorPhone.length <= 0 || !phoneRegex.test(donorPhone)) {
      setIsInvalidPhone(true);
      phoneInput.focus();
      return;
    } else {
      setDonorPhone(donorPhone);
    }

    return handleNext();
  };

  return (
    <>
      <StepLabel>Name and Reason</StepLabel>
      <StepContent>
        <Box>
          <FormGroup>
            <FormLabel component="legend">Collection Date/Time</FormLabel>
            <RadioGroup
              aria-label="collection date/time"
              defaultValue="now"
              name="collection-date-time"
              onChange={(e) => {
                setCollDate(e.target.value);
                setMinDateTime(new Date());
                setDateValue(new Date());
              }}
              row={true}
              value={collDate}
            >
              <FormControlLabel
                control={<Radio />}
                label="Now"
                value="now"
              />
              <FormControlLabel
                control={<Radio />}
                label="Schedule"
                value="schedule"
              />
            </RadioGroup>
            {collDate !== "now" &&
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                minDateTime={minDateTime}
                onChange={(newValue) => {
                  setDateValue(newValue);
                }}
                renderInput={(props) => <TextField {...props} />}
                value={dateValue}
              />
            </LocalizationProvider>}
            <TextField
              error={isInvalidDonor}
              id="outlined-basic"
              label="Donor Name"
              onChange={donorFieldChanged}
              value={donorName}
              variant="outlined"
            />
            <TextField
              error={isInvalidPhone}
              id="outlined-basic-phone"
              label="Requester Phone"
              onChange={donorPhoneChanged}
              value={donorPhone}
              variant="outlined"
            />
            <FormLabel component="legend" sx={{ marginTop: 1 }}>Reason for Collection</FormLabel>
            <RadioGroup
              aria-label="reason for collection"
              defaultValue="postaccident"
              name="radio-buttons-group"
              onChange={(e) => {
                setReason(e.target.value);
              }}
              value={reason}
            >
              <FormControlLabel
                control={<Radio />}
                label="Post-Accident"
                value="postaccident"
              />
              <FormControlLabel
                control={<Radio />}
                label="Reasonable Suspicion"
                value="reasonablesuspicion"
              />
              <FormControlLabel
                control={<Radio />}
                label="Random"
                value="random"
              />
              <FormControlLabel
                control={<Radio />}
                label="Other"
                value="other"
              />
            </RadioGroup>
          </FormGroup>
          <Button onClick={checkNextInputs}>Next</Button>
        </Box>
      </StepContent>
    </>
  );
}

export default NameAndReasonStep;
