import {
  StepLabel,
  StepContent,
  Box,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";

function CollectionLocationStep({
  locationValues,
  locationValue,
  setLocationValue = (f) => f,
  selectedIndex,
  setSelectedIndex = (f) => f,
  handleBack = (f) => f,
  handleClose = (f) => f,
}) {

  return (
    <>
      <StepLabel>Collection Location</StepLabel>
      <StepContent>
        <Box>
          <FormGroup>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Client</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={locationValue}
                label="Account"
                onChange={(e) => {
                  console.log("Selected Location: ", e.target.value);
                  setLocationValue(e.target.value);
                }}
              >
                {locationValues.map((x, i) => {
                  return (
                    <MenuItem key={x.LocationGUID} value={i}>
                      {x.Name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              {locationValues[locationValue].sites ? locationValues[locationValue].sites.map((x, i) => {
                return (
                  <ListItem key={x.CollectionSiteGUID}>
                    <ListItemButton
                      selected={selectedIndex === i}
                      onClick={(e) => {
                        setSelectedIndex(i);
                      }}
                    >
                      <ListItemText
                        primary={x.Name}
                        secondary={
                          <Typography
                            sx={{
                              display: "inline",
                              //whiteSpace: "pre-line",
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {`${x.Address1} ${x.Address2}\n${x.City} ${x.State}`}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }) : <></>}
            </List>
          </FormGroup>
          <Button onClick={handleBack}>Back</Button>
          <Button onClick={() => handleClose(true)}>Finish</Button>
        </Box>
      </StepContent>
    </>
  );
}

export default CollectionLocationStep;
