import { useContext, useEffect, useState } from "react";

import axios from "axios";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Modal, Stack, Typography } from "@mui/material";
import { CircleMarker, Marker, MapContainer, Popup, TileLayer, useMap } from "react-leaflet";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AuthContext from "./AuthContext";
import ChatButton from "./ChatButton";
import ChatContext from "./ChatContext";
import ChatModal from "./ChatModal";
import { isActionable } from "./tools";

// component for confirmation of cancelling a request
function ConfirmCancelDialog({
  cancelDialogOpen = false,
  request = {},
  setCancelDialogOpen = () => {},
  setParentModalOpen = () => {},
}) {
  const { accessToken } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setCancelDialogOpen(false);
  };

  const updateMutation = useMutation(
    async ({ guid, token, updateKey }) => {
      try {
        const r = await axios.put(
          `https://ahc.wolfeone.com/api/requesters/requests/${guid}`,
          { [updateKey]: true },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        );
        console.debug(r);
      } catch (e) {
        console.error(e);
        toast.error("Error updating request.");
      }
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["requests"]);
        if (variables?.updateKey === "Canceled") {
          setCancelDialogOpen(false);
          setParentModalOpen(false);
        }
      },
    }
  );

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleClose}
      open={cancelDialogOpen}
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm Request Cancelation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to cancel this request?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Do Not Cancel</Button>
        <Button
          onClick={() => {
            updateMutation.mutate({
              guid: request?.CollectionRequestGUID ?? "",
              token: accessToken,
              updateKey: "Canceled",
            });
          }}
          autoFocus
        >
          Yes, Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function getSiteAddress(guid, sites) {
  if (sites && guid) {
    const site = sites.find((x) => x.CollectionSiteGUID === guid);
    return `${site.Address1} ${site.Address2}, ${site.City} ${site.State} ${site.Zip}`;
  }
  return "";
}

export function getRequestId(request) {
  if (request.CollectionRequestGUID)
    return request.CollectionRequestGUID.split("-")[0];
  return "";
}

function getGoogleMapsLink(request, locations) {
  const siteAddress = getSiteAddress(
    request?.CollectionSiteGUID,
    locations.find((x) => x.LocationGUID === request.LocationGUID)?.sites
  );
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    siteAddress
  )}`;
}

export function MapSubcomponent({ latitude, longitude, request }) {
  const map = useMap();
  useEffect(() => {
    console.log("updating map center");
    if (request?.CollectorTrackable && isActionable(request, "Cancel")) {
      map.fitBounds([
        [latitude, longitude],
        [request?.CollectorLatitude, request?.CollectorLongitude],
      ]);
    } else {
      map.setView([latitude, longitude]);
    }
  }, [latitude, longitude, map, request]);
  return null;
}

function RequestStatus({
  locations = [],
  open = false,
  requestObject: request = {},
  setOpen = (f) => f,
}) {
  const [chatOpen, setChatOpen] = useState(false);
  const [latitude, setLatitude] = useState(51.505);
  const [longitude, setLongitude] = useState(-0.09);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const apiKey = process.env.REACT_APP_TOMTOM_KEY;
  const emptyResult = {
    position: {
      lat: 0,
      lon: 0,
    },
    viewport: null,
  };

  const { data: locationCoordinates } = useQuery(
    [request?.LocationGUID, request?.CollectionSiteGUID],
    async ({ queryKey }) => {
      const [locationGUID, collectionSiteGUID] = queryKey;
      if (!locationGUID) {
        return emptyResult;
      }
      const siteAddress = getSiteAddress(
        collectionSiteGUID,
        locations.find((x) => x.LocationGUID === locationGUID)?.sites
      );
      const encodedAddress = encodeURIComponent(siteAddress);
      const url = `https://api.tomtom.com/search/2/search/${encodedAddress}.json?key=${apiKey}`;
      try {
        const { data } = await axios.get(url);
        if (!data?.results.length) {
          return emptyResult;
        }
        return {
          position: data.results[0].position,
          viewport: data.results[0].viewport,
        };
      } catch (e) {
        console.error(e);
        toast.error("Error getting location coordinates");
      }
      return emptyResult;
    }
  );

  useEffect(() => {
    if (locationCoordinates) {
      setLatitude(locationCoordinates?.position.lat);
      setLongitude(locationCoordinates?.position.lon);
    }
  }, [locationCoordinates]);

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          left: "50%",
          maxHeight: "80vh",
          maxWidth: "80vw",
          overflow: "scroll",
          p: 4,
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="column"
          justifyContent="flex-start"
          spacing={0.25}
        >
          <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: "auto" }}>
            <CloseIcon />
          </IconButton>
          {request ? (
            <>
              <Typography
                color="text.primary"
                component="div"
                sx={{ display: "inline" }}
                variant="h5"
              >
                {`Collection ID: ${getRequestId(request)}`}
              </Typography>
              <Typography
                color="text.primary"
                component="div"
                sx={{ display: "inline" }}
                variant="h6"
              >
                {`Donor: ${request.DonorName}`}
              </Typography>
              <Typography
                color="text.primary"
                component="div"
                sx={{ display: "inline" }}
                variant="h6"
              >
                {`Status: ${request.Status}`}
              </Typography>
              <Typography
                color="text.primary"
                component="div"
                sx={{ display: "inline" }}
                variant="h6"
              >
                {`Location: ${request.LocationName}`}
              </Typography>
              <Typography
                color="text.primary"
                component="div"
                sx={{ display: "inline" }}
                variant="h6"
              >
                Site:{" "}
                <Link
                  href={getGoogleMapsLink(request, locations)}
                  rel="noopener"
                  target="_blank"
                >{`${request.CollectionSiteName}`}</Link>
              </Typography>
              {isActionable(request, "Cancel") &&
              <Button
                color="secondary"
                onClick={() => {
                  setCancelDialogOpen(true);
                }}
                style={{
                  marginBottom: "1vh",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "1vh",
                }}
                variant="contained"
              >
                CANCEL REQUEST
              </Button>}
              <Box
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  height: 300,
                  maxWidth: "70vw",
                  p: 4,
                  width: 300,
                }}
              >
                <MapContainer
                  center={[latitude, longitude]}
                  scrollWheelZoom={false}
                  style={{ height: "100%" }}
                  zoom={13}
                >
                  <MapSubcomponent
                    latitude={latitude}
                    longitude={longitude}
                    request={request}
                  />
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[latitude, longitude]}>
                    <Popup>{`${request.CollectionSiteName}`}</Popup>
                  </Marker>

                  {request?.CollectorTrackable && isActionable(request, "Cancel") && (
                    <CircleMarker
                      center={[
                        request?.CollectorLatitude,
                        request?.CollectorLongitude,
                      ]}
                      pathOptions={{ color: "red" }}
                    />
                  )}
                </MapContainer>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Stack>
        <ChatContext.Provider
          value={{
            chatOpen,
            setChatOpen,
          }}
        >
          <ChatButton
            active={isActionable(request, "Cancel")}
            conditionfn={() => {
              return request?.CollectorGUID !== null;
            }}
            isCollector={false}
            request={request}
          />
          <ChatModal
            active={isActionable(request, "Cancel")}
            isCollector={false}
            guid={request?.CollectionRequestGUID}
          />
        </ChatContext.Provider>
        <ConfirmCancelDialog
          cancelDialogOpen={cancelDialogOpen}
          request={request}
          setCancelDialogOpen={setCancelDialogOpen}
          setParentModalOpen={setOpen}
        />
      </Box>
    </Modal>
  );
}

export default RequestStatus;
