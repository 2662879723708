import { useState, useEffect, useCallback, useContext } from "react";

import axios from "axios";
import { Box, Button, Stack } from "@mui/material";
// TODO: uncomment for privacy
// import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { addDays } from "date-fns";
import { AssignmentTurnedIn, DepartureBoard, PendingActions, } from "@mui/icons-material";
import { isEmpty } from "lodash";
// TODO: uncomment for privacy
import { /* useMutation, */ useQuery /*, useQueryClient */ } from "react-query";
import toast from "react-hot-toast";

import AuthContext from "./AuthContext";
import { convertToFromUTC } from "./tools";
import RequesterModal from "./RequesterModal";
import RequestList from "./RequestList";
import RequestStatus from "./RequestStatus";
import RequestToolbar from "./RequesterToolbar";

export async function getRequestLists(token, isLoggedIn) {
  const offsetDate = convertToFromUTC(addDays(new Date(), -32));
  const urlRoot = "https://ahc.wolfeone.com/api/requesters/requests";
  const url = `${urlRoot}?from=${offsetDate}`;

  if (!token) {
    return {
      pending: [],
      inProcess: [],
      completed: [],
    };
  }

  try {
    let { data } = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const statusFilter = (status) => data.filter((x) => x.Status === status);
    return {
      pending: statusFilter("Pending"),
      inProcess: statusFilter("In Process").concat(statusFilter("Arrived")),
      completed: statusFilter("Complete").concat(statusFilter("No Show")),
    };
  } catch (error) {
    if (isLoggedIn) {
      console.debug(error);
      toast.error("Error getting request list");
    }

    return {
      pending: [],
      inProcess: [],
      completed: [],
    };
  }
}

async function getLocations(token, isLoggedIn) {
  const url = "https://ahc.wolfeone.com/api/requesters/locations";

  if (!token) {
    return [];
  }

  try {
    const { data } = await axios.get(url, {
      headers: { authorization: `Bearer ${token}` },
    });

    return Promise.all(
      data.map(async (x) => {
        const siteURL = `https://ahc.wolfeone.com/api/requesters/locations/${x.LocationGUID}/sites`;
        const { data: siteData } = await axios.get(siteURL, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return { sites: siteData, ...x };
      })
    );
  } catch (error) {
    if (isLoggedIn) {
      console.debug(error);
      toast.error("Error getting locations");
    }

    return [];
  }
}

function RequesterHome() {
  const [isHomeLoading, setIsHomeLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [inProcessRequests, setInProcessRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [collDate, setCollDate] = useState("now");
  const [minDateTime, setMinDateTime] = useState(new Date());
  const [dateValue, setDateValue] = useState(new Date());
  const [locations, setLocations] = useState([]);
  const [statusOpen, setStatusOpen] = useState(false);
  const [requestDetail, setRequestDetail] = useState();
  // TODO: uncomment this for privacy
  // const [isPrivate, setIsPrivate] = useState(false);
  const [requesterPhone, setRequesterPhone] = useState("");

  const { accessToken, isLoggedIn } = useContext(AuthContext);

  const handleOpen = () => {
    setCollDate("now");
    setMinDateTime(new Date());
    setDateValue(new Date());
    setOpen(true);
  }

  // TODO: uncomment for privacy 
  // const queryClient = useQueryClient();

  const { data: locationsData } = useQuery(
    ["locations", accessToken],
    ({ queryKey }) => {
      const [, token] = queryKey;
      return getLocations(token, isLoggedIn);
    }
  );

  const { data: requestListData } = useQuery(
    ["requests", accessToken],
    ({ queryKey }) => {
      const [, token] = queryKey;
      console.log("fetching requests", new Date());
      return getRequestLists(token, isLoggedIn);
    },
    {
      refetchInterval: 10000,
    }
  );

  // TODO: uncomment this for privacy
  // const { data: isPrivateData } = useQuery(
  //   ["isPrivate", accessToken],
  //   async ({ queryKey }) => {
  //     const [, token] = queryKey;
  //     if (!token) {
  //       return isPrivate;
  //     }
  //     try {
  //       const { data } = await axios.get(
  //         "https://ahc.wolfeone.com/api/requesters",
  //         {
  //           headers: {
  //             authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       return !data.Private;
  //     } catch (error) {
  //       if (isLoggedIn) {
  //         console.debug(error);
  //         toast.error("Error getting requester status");
  //       }
  //       return isPrivate;
  //     }
  //   }
  // );

  const { data: requesterPhoneData } = useQuery(
    ["requesterPhone", accessToken],
    async ({ queryKey }) => {
      const [, token] = queryKey;
      if (!token) {
        return requesterPhone;
      }
      try {
        const { data } = await axios.get(
          "https://ahc.wolfeone.com/api/requesters",
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        return data?.Phone;
      } catch (error) {
        if (isLoggedIn) {
          console.debug(error);
          toast.error("Error getting requester phone");
        }
        return requesterPhone;
      }
    }
  );

  // TODO: uncomment this for privacy
  // const isPrivateMutation = useMutation(
  //   async ({ token, checked }) => {
  //     await axios.put(
  //       "https://ahc.wolfeone.com/api/requesters",
  //       { Private: !checked },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //   },
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["isPrivate"]);
  //     },
  //     onError: () => {
  //       if (isLoggedIn) {
  //         toast.error("Error updating requester status");
  //       }
  //     },
  //   }
  // );

  const requestDetailCallback = useCallback(
    (data) => {
      console.log("callback data: ", data);
      setPendingRequests(data?.pending);
      setInProcessRequests(data?.inProcess);
      setCompletedRequests(data?.completed);
      if (!isEmpty(requestDetail)) {
        const findfun = (x) =>
          x?.CollectionRequestGUID === requestDetail?.CollectionRequestGUID;
        const newRequest =
          data?.pending.find(findfun) ??
          data?.inProcess.find(findfun) ??
          data?.completed.find(findfun);
        if (newRequest) {
          setRequestDetail(newRequest);
        }
      }
    },
    [requestDetail]
  );

  useEffect(() => {
    if (accessToken) {
      requestDetailCallback(requestListData);
      setLocations(locationsData);
      setIsHomeLoading(false);
      // TODO: uncomment this for privacy
      // if (isPrivateData !== undefined) {
      //   setIsPrivate(isPrivateData);
      // }
      if (requesterPhone !== undefined) {
        setRequesterPhone(requesterPhoneData);
      }
    }
  }, [
    accessToken,
    locationsData,
    requestListData,
    // TODO: uncomment this for privacy
    // isPrivateData,
    requesterPhone,
    requesterPhoneData,
    requestDetailCallback,
  ]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <RequestToolbar />
      <Stack style={{ margin: "20px" }} spacing={2}>
        <Button
          onClick={handleOpen}
          color="secondary"
          style={{
            maxWidth: "200px",
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          size="small"
          fullWidth={false}
          variant="contained"
        >
          + REQUEST COLLECTION
        </Button>
        {/* 
          // TODO: uncomment this for privacy
          <FormGroup>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) =>
                  isPrivateMutation.mutate({
                    token: accessToken,
                    checked: e.target.checked,
                  })
                }
                checked={isPrivate}
              />
            }
            label={"Share My Phone Number"}
          />
        </FormGroup> 
        */}
        <RequestStatus
          locations={locations}
          open={statusOpen}
          requestObject={requestDetail}
          setOpen={setStatusOpen}
        />
        <RequesterModal
          accessToken={accessToken}
          collDate={collDate}
          dateValue={dateValue}
          locationValues={locations}
          minDateTime={minDateTime}
          open={open}
          requesterPhone={requesterPhone}
          setCollDate={setCollDate}
          setCompletedRequests={setCompletedRequests}
          setDateValue={setDateValue}
          setInProcessRequests={setInProcessRequests}
          setMinDateTime={setMinDateTime}
          setOpen={setOpen}
          setPendingRequests={setPendingRequests}
        />
        <RequestList
          heading="Pending"
          iconFn={() => <PendingActions />}
          isLoading={isHomeLoading}
          requests={pendingRequests}
          setOpen={setStatusOpen}
          setRequestDetail={setRequestDetail}
        />
        <RequestList
          heading="In process"
          iconFn={() => <DepartureBoard />}
          isLoading={isHomeLoading}
          requests={inProcessRequests}
          setOpen={setStatusOpen}
          setRequestDetail={setRequestDetail}
        />
        <RequestList
          heading="Completed"
          iconFn={() => <AssignmentTurnedIn />}
          isLoading={isHomeLoading}
          requests={completedRequests}
          setOpen={setStatusOpen}
          setRequestDetail={setRequestDetail}
        />
      </Stack>
    </Box>
  );
}

export default RequesterHome;
