import { useContext, useEffect, useState } from "react";

import axios from "axios";
import { Box, Button, IconButton, Modal, Stack, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { useMutation } from "react-query";

import AuthContext from "./AuthContext";

function NotesModal({
  guid,
  open = false,
  prompt="Collection Notes",
  request,
  setOpen = (f) => f,
}) {
  const [notes, setNotes] = useState("");
  
  const { accessToken, isLoggedIn } = useContext(AuthContext);

  const notesMutation = useMutation(async ({ guid, notes, token }) => {
    try {
      return !token
        ? {}
        : await axios.put(
            `https://ahc.wolfeone.com/api/collectors/requests/${guid}`,
            { Notes: notes },
            {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
            }
          );
    } catch (e) {
      if(isLoggedIn) {
        console.error(e);
        toast.error("Error updating request notes.");
      }
    }
    return {};
  });

  useEffect(() => {
    setNotes(request.Notes);
  }, [request.Notes]);

  return (
    <>
      <Modal
        aria-describedby="child-modal-description"
        aria-labelledby="child-modal-title"
        hideBackdrop
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            left: "50%",
            maxWidth: "70vw",
            pb: 3,
            position: "absolute",
            pt: 2,
            px: 4,
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Stack>
            <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: "auto" }}>
              <CloseIcon />
            </IconButton>
            <TextField
              id="outlined-multiline-static"
              label={prompt}
              multiline
              onChange={(e) => setNotes(e.target.value)}
              rows={4}
              value={notes}
            />
            <Button
              onClick={() => {
                notesMutation.mutate({
                  guid,
                  notes,
                  token: accessToken,
                });
                setOpen(false);
              }}
              variant="outlined"
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default NotesModal;
