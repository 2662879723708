import { useCallback, useContext, useEffect, useState } from "react";

import axios from "axios";
import { Box, Button, Grid, IconButton, ListItem, ListItemText, Modal, Stack, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AuthContext from "./AuthContext";
import ChatContext from "./ChatContext";
import { convertToFromUTCString } from "./tools";
import getTheme from "./ThemeLookup";

function ChatModal({ active, isCollector = false, guid }) {
  const { accessToken, isLoggedIn, organization } = useContext(AuthContext);
  const { setChatOpen: setOpen, chatOpen: open } = useContext(ChatContext);
  const theme = getTheme(organization);
  const queryClient = useQueryClient();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const { data: messageData } = useQuery(
    ["messages", guid, accessToken],
    async ({ queryKey }) => {
      const [, guid, token] = queryKey;
      try {
        const { data } = await axios.get(
          `https://ahc.wolfeone.com/api/${
            isCollector ? "collectors" : "requesters"
          }/requests/${guid}/messages?markRead=True`,
          { headers: { authorization: `Bearer ${token}` } }
        );
        console.debug("messages: ", data);
        return data;
      } catch (error) {
        if (isLoggedIn) {
          console.error(error);
          toast.error("Error loading chat");
        }
      }
      return {};
    },
    {
      refetchInterval: 10000,
      enabled: open,
    }
  );

  const chatMutation = useMutation(
    async ({ token, isCollector, guid, message }) => {
      try {
        return !token
          ? {}
          : await axios.post(
              `https://ahc.wolfeone.com/api/${
                isCollector ? "collectors" : "requesters"
              }/requests/${guid}/messages`,
              { Message: message },
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${token}`,
                },
              }
            );
      } catch (error) {
        if (isLoggedIn) {
          console.error(error);
          toast.error("Error sending message");
        }
      }
      return {};
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["messages", guid]);
        if (isCollector) {
          queryClient.invalidateQueries(["collectorRequests"]);
        } else {
          queryClient.invalidateQueries(["requests"]);
        }
      },
    }
  );

  const updateMessagesCallback = useCallback((data) => {
    setMessages(data?.Messages ?? []);
  }, []);

  useEffect(() => {
    if (open) {
      updateMessagesCallback(messageData);
    }
  }, [messageData, updateMessagesCallback, open]);

  const getAlignment = (x) => {
    const alignment =
      x?.Role !== "Requester"
        ? isCollector
          ? "right"
          : "left"
        : isCollector
          ? "left"
          : "right";
    return alignment;
  };

  const getStyle = (x) => {
    const style =
      getAlignment(x) === "left"
        ?
        {
          background: "#F8F4F4",
          borderRadius: 3,
          color: "#000000",
          display: "inline-block",
          padding: 1,
          maxWidth: "70%"
        }
        :
        {
          background: theme.palette.primary.main,
          borderRadius: 3,
          color: "#FFFFFF",
          display: "inline-block",
          float: "right",
          padding: 1,
          maxWidth: "70%"
        }
    return style;
  }

  return (
    <>
      <Modal
        aria-describedby="child-modal-description"
        aria-labelledby="child-modal-title"
        hideBackdrop
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            left: "50%",
            maxWidth: "80vw",
            pb: 3,
            position: "absolute",
            pt: 2,
            px: 4,
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Stack
            alignItems="flex-start"
            direction="column"
            justifyContent="flex-start"
            spacing={2}
          >
            <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: "auto" }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction="column-reverse" style={{ overflowY: "auto", height: "40vh" }}>
            {messages.map((x) => {
              return (
                <ListItem key={x?.MessageGUID}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText
                        primary={x.Message}
                        sx={getStyle(x)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText
                        align={getAlignment(x)}
                        secondary={convertToFromUTCString(x.CreatedAt, false, "M/dd/yy h:mm a")}
                        sx={{ color: "#6E6969" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </Stack>
          {active &&
          <Stack>
            <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
              <TextField
                label="Message"
                multiline
                onChange={(e) => setMessage(e.target.value)}
                sx={{ width: "74%" }}
                value={message}
              />
              <Button
                onClick={() => {
                  chatMutation.mutate({
                    token: accessToken,
                    isCollector,
                    guid,
                    message,
                  });
                  setMessage("");
                }}
                sx={{ width: "24%" }}
                variant="outlined"
              >
                Send
              </Button>
            </Stack>
          </Stack>}
        </Box>
      </Modal>
    </>
  );
}

export default ChatModal;
