import { useContext, useEffect, useState } from "react";

import axios from "axios";
import { Box, IconButton, Modal, Stack, Step, Stepper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import toast from "react-hot-toast";
import { useMutation } from "react-query";

import AuthContext from "./AuthContext";
import CollectionLocationStep from "./CollectionLocationStep";
import CollectionTypeStep from "./CollectionTypeStep";
import { convertToFromUTC } from "./tools";
import { getRequestLists } from "./RequesterHome";
import NameAndReasonStep from "./NameAndReasonStep";

function RequesterModal({
  collDate,
  dateValue,
  locationValues = [],
  minDateTime,
  open = false,
  requesterPhone = "",
  setCollDate = (f) => f,
  setCompletedRequests = (f) => f,
  setDateValue = (f) => f,
  setInProcessRequests = (f) => f,
  setMinDateTime = (f) => f,
  setOpen = (f) => f,
  setPendingRequests = (f) => f,
}) {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [donorName, setDonorName] = useState("");
  const [donorPhone, setDonorPhone] = useState(requesterPhone);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [locationValue, setLocationValue] = useState(0);
  const [useDOT, setUseDOT] = useState(false);
  const [testAlcohol, setTestAlcohol] = useState(false);
  const [modality, setModality] = useState("fmcsa");
  const [reason, setReason] = useState("postaccident");

  const { accessToken, isLoggedIn } = useContext(AuthContext);

  const requestMutation = useMutation(
    async ({ token, data }) => {
      const url = "https://ahc.wolfeone.com/api/requesters/requests";
      try {
        return !token
          ? {}
          : axios.post(url, data, {
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
              },
            });
      } catch (error) {
        if (isLoggedIn) {
          console.log(error);
          toast.error("Error submitting request");
        }
      }
      return {};
    },
    {
      onSuccess: () => {
        getRequestLists(accessToken, isLoggedIn).then((data) => {
          setPendingRequests(data.pending);
          setInProcessRequests(data.inProcess);
          setCompletedRequests(data.completed);
        });

        // clear out request data
        setActiveStep(0);
        setDateValue(new Date());
        setDonorName("");
        setSelectedIndex(0);
        setLocationValue(0);
        setUseDOT(false);
        setTestAlcohol(false);
        setDonorPhone(requesterPhone);
        setModality("fmcsa");
        setReason("postaccident");
      },
    }
  );

  const handleClose = (doMutation) => {
    if (doMutation) {
      requestMutation.mutate({
        token: accessToken,
        data: {
          LocationGUID: locationValues[locationValue].LocationGUID,
          CollectionSiteGUID:
            locationValues[locationValue].sites[selectedIndex]
              .CollectionSiteGUID,
          DonorName: donorName,
          testReason: reason,
          DOT: useDOT,
          Modality: useDOT ? modality : null,
          Alcohol: testAlcohol,
          RequesterPhone: donorPhone,
          CreatedAt: collDate !== "now" ? convertToFromUTC(new Date(dateValue)) : null,
        },
      });
    }
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    // load the locations
  });

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          left: "50%",
          maxHeight: "80vh",
          maxWidth: "80vw",
          overflow: "scroll",
          p: 4,
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
        }}
      >
        <Stack direction="row" style={{ marginBottom: "5px", marginRight: "auto" }}>
          <IconButton onClick={() => setShowDisclaimer(true)}>
            <HelpOutlineOutlinedIcon style={{ marginRight: "5px" }} />
            <Typography color="text.primary" component="h2" variant="h6">
              Disclaimer
            </Typography>
          </IconButton>
        </Stack>
        <Modal
          aria-describedby="child-modal-description"
          aria-labelledby="child-modal-title"
          onClose={() => setShowDisclaimer(false)}
          open={showDisclaimer}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              left: "50%",
              maxWidth: "80vw",
              pb: 3,
              position: "absolute",
              pt: 2,
              px: 4,
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
            }}
          >
            <Stack direction="row" style={{ marginBottom: "5px" }}>
              <Typography component="h2" variant="h6">
                Disclaimer
              </Typography>
              <IconButton onClick={() => setShowDisclaimer(false)} sx={{ marginLeft: "auto" }}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography component="p">
              Please note that our regular turn around time is within one hour of submitting the request for a collector. If you do not hear from someone or your collection does not get accepted, please note that other people are being notified.
            </Typography>
          </Box>
        </Modal>
        <Stack direction="row">
          <Typography component="h2" id="modal-modal-title" variant="h6">
            Create Collection
          </Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: "auto" }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="Name and Reason">
            <NameAndReasonStep
              collDate={collDate}
              dateValue={dateValue}
              donorName={donorName}
              donorPhone={donorPhone}
              handleNext={handleNext}
              minDateTime={minDateTime}
              reason={reason}
              setCollDate={setCollDate}
              setDateValue={setDateValue}
              setDonorName={setDonorName}
              setDonorPhone={setDonorPhone}
              setMinDateTime={setMinDateTime}
              setReason={setReason}
            />
          </Step>
          <Step key="Collection Type">
            <CollectionTypeStep
              handleBack={handleBack}
              handleNext={handleNext}
              modality={modality}
              setModality={setModality}
              setTestAlcohol={setTestAlcohol}
              setUseDOT={setUseDOT}
              testAlcohol={testAlcohol}
              useDOT={useDOT}
            />
          </Step>
          <Step key="Collection Location">
            <CollectionLocationStep
              handleBack={handleBack}
              handleClose={handleClose}
              locationValue={locationValue}
              locationValues={locationValues}
              selectedIndex={selectedIndex}
              setLocationValue={setLocationValue}
              setSelectedIndex={setSelectedIndex}
            />
          </Step>
        </Stepper>
      </Box>
    </Modal>
  );
}

export default RequesterModal;
