import { addMilliseconds, format, parseISO } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

export function convertToFromUTC(date, toUTC = true, outputFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS") {
    if (!date) return null;
    const offset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return format(addMilliseconds(date, toUTC ? -offset : offset), outputFormat);
}
  
export function convertToFromUTCString(inputString, toUTC = true, outputFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS") {
    if (!inputString) return null;
    const offset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return format(addMilliseconds(parseISO(inputString), toUTC ? -offset : offset), outputFormat);
}

export function isActionable (request, action) {
    switch(action) {
        case "Cancel":
            return request?.Status === "Pending"
                || request?.Status === "In Process"
                || request?.Status === "Arrived";
        case "Accept":
            return request?.Status === "Pending"
                && request?.CollectorGUID === null;
        case "Arrive":
            return request?.Status === "In Process";
        case "No Show":
        case "Complete":
            return request?.Status === "Arrived";
        default:
            return false;
    }
}
