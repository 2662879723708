import { useContext, useEffect, useState } from "react";

import axios from "axios";
import { Box, Button, IconButton, Link, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getRequestId, MapSubcomponent } from "./RequestStatus";
import { isEmpty } from "lodash";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AuthContext from "./AuthContext";
import ChatButton from "./ChatButton";
import ChatContext from "./ChatContext";
import ChatModal from "./ChatModal";
import { isActionable } from "./tools";
import NotesModal from "./NotesModal";

function unmapReason(reason) {
  const reasons = {
    postaccident: "Post-Accident",
    reasonablesuspicion: "Reasonable Suspicion",
    other: "Other",
    random: "Random",
  };
  return reasons[reason];
}

function getRequestAddress(request) {
  return `${request?.CollectionSiteAddress1 ?? ""} ${
    request?.CollectionSiteAddress2 ?? ""
  }, ${request?.CollectionSiteCity ?? ""} ${
    request?.CollectionSiteState ?? ""
  } ${request?.CollectionSiteZip ?? ""}`;
}

function getGoogleMapsLink(request) {
  const siteAddress = getRequestAddress(request);

  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    siteAddress
  )}`;
}

function CollectorModal({ open = false, setOpen = (f) => f, request = {} }) {
  const [latitude, setLatitude] = useState(51.505);
  const [longitude, setLongitude] = useState(-0.09);
  const apiKey = process.env.REACT_APP_TOMTOM_KEY;
  const queryClient = useQueryClient();
  const [chatOpen, setChatOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const { accessToken, isLoggedIn, userType } = useContext(AuthContext);
  const [notesPrompt, setNotesPrompt] = useState("Collection Notes");
  const emptyResult = {
    position: {
      lat: 0,
      lon: 0,
    },
    viewport: null,
  };

  const { data: locationCoordinates } = useQuery(
    ["collectorModalLocation", request],
    async ({ queryKey }) => {
      const [, request] = queryKey;
      if (isEmpty(request)) {
        return emptyResult;
      }
      const siteAddress = getRequestAddress(request);
      const encodedAddress = encodeURIComponent(siteAddress);
      const url = `https://api.tomtom.com/search/2/search/${encodedAddress}.json?key=${apiKey}`;
      try {
        const { data } = await axios.get(url);
        if (!data?.results.length) {
          return emptyResult;
        }
        return {
          position: data.results[0].position,
          viewport: data.results[0].viewport,
        };
      } catch (error) {
        if (isLoggedIn) {
          console.error(error);
          toast.error("Error getting collector location.");
        }
      }
      return emptyResult;
    }
  );

  const updateMutation = useMutation(
    async ({ guid, token, updateKey }) => {
      try {
        const r = await axios.put(
          `https://ahc.wolfeone.com/api/collectors/requests/${guid}`,
          { [updateKey]: true },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        );
        console.debug(r);
      } catch (e) {
        if (isLoggedIn) {
          console.error(e);
          toast.error("Error updating collector request.");
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["collectorRequests"]);
      },
    }
  );

  useEffect(() => {
    if (locationCoordinates) {
      setLatitude(locationCoordinates?.position.lat);
      setLongitude(locationCoordinates?.position.lon);
    }
  }, [locationCoordinates]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          left: "50%",
          maxHeight: "80vh",
          maxWidth: "80vw",
          overflow: "scroll",
          p: 4,
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="column"
          justifyContent="flex-start"
          spacing={2}
        >
          <IconButton onClick={() => setOpen(false)} sx={{ marginLeft: "auto" }}>
            <CloseIcon />
          </IconButton>
          {request ? (
            <>
              <Typography
                color="text.primary"
                component="div"
                sx={{ display: "inline" }}
                variant="h5"
              >
                {`Collection ID: ${getRequestId(request)}`}
              </Typography>
              <Stack
                alignItems="flex-start"
                direction="column"
                justifyContent="flex-start"
                spacing={0}
              >
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="subtitle2"
                >
                  Requester Contact:{" "}
                  <Link
                    href={`tel:${request?.RequesterPhone ?? "555-555-5555"}`}
                  >
                    {`${request?.RequesterPhone ?? "555-555-5555"}`}
                  </Link>
                </Typography>

                {
                  // TODO: uncomment if private
                  // !request?.RequesterPrivate ? (
                  //   <Typography
                  //     color="text.primary"
                  //     component="div"
                  //     sx={{ display: "inline" }}
                  //     variant="subtitle2"
                  //   >
                  //     Requester Contact:{" "}
                  //     <Link
                  //       href={`tel:${
                  //         request?.RequesterPhone ?? "555-555-5555"
                  //       }`}
                  //     >
                  //       {`${request?.RequesterPhone ?? "555-555-5555"}`}
                  //     </Link>
                  //   </Typography>
                  // ) : (
                  //   <Typography
                  //     color="text.primary"
                  //     component="div"
                  //     sx={{ display: "inline" }}
                  //     variant="subtitle2"
                  //   >
                  //     Requester is Private
                  //   </Typography>
                  // )}
                }
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="subtitle2"
                >
                  {`Reason: ${unmapReason(request.TestReason)}`}
                </Typography>
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="subtitle2"
                >
                  {`Alcohol: ${request.Alcohol ? "YES" : "NO"}`}
                </Typography>
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="subtitle2"
                >
                  {`Regulation: ${request.DOT ? "DOT" : "NON-DOT"} ${
                    request.DOT ? request.Modality.toUpperCase() : ""
                  }`}
                </Typography>
                {userType === "supervisor" ? (
                  <Typography
                    color="text.primary"
                    component="div"
                    sx={{ display: "inline" }}
                    variant="h6"
                  >
                    {`Collector: ${request.CollectorName}`}
                  </Typography>
                ) : null}
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="h6"
                >
                  {`Donor: ${request.DonorName}`}
                </Typography>
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="h6"
                >
                  {`Status: ${request.Status}`}
                </Typography>
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="h6"
                >
                  {`Location: ${request.LocationName}`}
                </Typography>
                <Typography
                  color="text.primary"
                  component="div"
                  sx={{ display: "inline" }}
                  variant="h6"
                >
                  Collection Site:{" "}
                  <Link
                    href={getGoogleMapsLink(request)}
                    rel="noopener"
                    target="_blank"
                  >{`${request.CollectionSiteName}`}</Link>
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                {isActionable(request, "Accept") &&
                <Button
                  color="secondary"
                  onClick={() => {
                    updateMutation.mutate({
                      guid: request?.CollectionRequestGUID ?? "",
                      token: accessToken,
                      updateKey: "Accepted",
                    });
                  }}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  variant="contained"
                >
                  ACCEPT REQUEST
                </Button>}
                {isActionable(request, "Arrive") &&
                <Button
                  color="secondary"
                  onClick={() => {
                    updateMutation.mutate({
                      guid: request?.CollectionRequestGUID ?? "",
                      token: accessToken,
                      updateKey: "Arrived",
                    });
                  }}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  variant="contained"
                >
                  I HAVE ARRIVED
                </Button>}
                {isActionable(request, "Complete") &&
                <Button
                  color="secondary"
                  onClick={() => {
                    setNotesPrompt("Enter COC or Notes");
                    setNotesOpen(true);
                    updateMutation.mutate({
                      guid: request?.CollectionRequestGUID ?? "",
                      token: accessToken,
                      updateKey: "Completed",
                    });
                  }}
                  variant="contained"
                >
                  COMPLETE COLLECTION
                </Button>}
                {isActionable(request, "No Show") &&
                <Button
                  color="secondary"
                  onClick={() => {
                    setNotesPrompt("Enter COC or Notes");
                    setNotesOpen(true);
                    updateMutation.mutate({
                      guid: request?.CollectionRequestGUID ?? "",
                      token: accessToken,
                      updateKey: "NoShow",
                    });
                  }}
                  variant="contained"
                >
                  NO SHOW
                </Button>}
              </Stack>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  height: 300,
                  margin: "auto",
                  maxWidth: "70vw",
                  p: 4,
                  width: 300,
                }}
              >
                <MapContainer
                  center={[latitude, longitude]}
                  scrollWheelZoom={false}
                  style={{
                    height: "100%",
                    margin: "auto",
                  }}
                  zoom={13}
                >
                  <MapSubcomponent latitude={latitude} longitude={longitude} />
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[latitude, longitude]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                </MapContainer>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Stack>
        <ChatContext.Provider
          value={{
            chatOpen,
            setChatOpen,
          }}
        >
          <ChatButton
            active={isActionable(request, "Cancel")}
            conditionfn={() => {
              return request?.CollectorGUID !== null;
            }}
            isCollector={true}
            request={request}
            setNotesOpen={setNotesOpen}
          />
          <ChatModal
            active={isActionable(request, "Cancel")}
            isCollector={true}
            guid={request?.CollectionRequestGUID}
          />
          <NotesModal
            guid={request?.CollectionRequestGUID}
            open={notesOpen}
            prompt={notesPrompt}
            request={request}
            setOpen={setNotesOpen}
          />
        </ChatContext.Provider>
      </Box>
    </Modal>
  );
}

export default CollectorModal;
