import { useState } from "react";
import { Collapse, IconButton, ListItemIcon, Toolbar } from "@mui/material";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { PendingActions } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { convertToFromUTCString } from "./tools";

function RequestList({
  defaultCollapsed = false,
  heading = "",
  iconFn = () => <PendingActions />,
  isLoading = false,
  requests = [],
  setOpen = (f) => f,
  setRequestDetail = (f) => f,
}) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  return (
    <>
      <Toolbar disableGutters={true}>
        <IconButton onClick={() => { setCollapsed(!collapsed); }}>
          {collapsed ? <AddIcon sx={{marginRight: "5px", marginLeft: "5px"}} /> : <RemoveIcon sx={{marginRight: "5px", marginLeft: "5px"}} />}
        </IconButton>
        <Typography
          align="left"
          component="div"
          sx={{ flexGrow: 1 }}
          variant="h5"
        >
          {heading}
        </Typography>
      </Toolbar>
      {isLoading ? (
        <Box sx={{ justifyContent: "center" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Collapse in={!collapsed} unmountOnExit timeout="auto">
          <List>
            {requests.map((request) => (
              <ListItemButton
                key={request.CollectionRequestGUID}
                onClick={() => {
                  setRequestDetail(request);
                  setOpen(true);
                }}
                sx={{ background: '#FFFFFF',  borderRadius: 3, marginBottom: 1 }}
              >
                <ListItemIcon>{iconFn()}</ListItemIcon>
                <ListItem disablePadding>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          color="text.primary"
                          component="span"
                          sx={{ display: "inline" }}
                          variant="body1"
                        >
                          {`${request.DonorName.toUpperCase()}`}
                        </Typography>
                        <Typography />
                        <Typography
                          color="text.primary"
                          component="span"
                          sx={{ display: "inline" }}
                          variant="body2"
                        >
                          {`${request.CollectionSiteName}`}
                        </Typography>
                      </>
                    }
                    secondary={`${convertToFromUTCString(request.CreatedAt, false, "M/dd/yy h:mm a")}`}
                  />
                </ListItem>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default RequestList;
