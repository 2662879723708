import { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import AppBar from "@mui/material/AppBar";
import { Button, Stack } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Settings } from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AuthContext from "./AuthContext";

function CollectorToolbar({ settingsVisible, setSettingsVisible }) {
  const { logout } = useAuth0();
  const { organization } = useContext(AuthContext);

  return (
    <AppBar position="static">
      <Toolbar>
        <Stack onClick={() => setSettingsVisible(!settingsVisible)}>
          <Settings />
          {!settingsVisible && <ArrowDropUp style={{ marginBottom: "-10px", marginTop: "-5px" }} />}
           {settingsVisible && <ArrowDropDown style={{ marginBottom: "-10px", marginTop: "-5px" }} />}
        </Stack>
        <Typography
          component="div"
          sx={{ flexGrow: 1 }}
          variant="h6"
        >
          Collector Home
        </Typography>
        <Button
          color="secondary"
          onClick={async () => {
            logout({
              returnTo: `${window.location.origin}/?org_id=${organization}`,
            });
          }}
          variant="contained"
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default CollectorToolbar;
