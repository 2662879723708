import { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AuthContext from "./AuthContext";

function RequestToolbar() {
  const { logout } = useAuth0();
  const { organization } = useContext(AuthContext);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          //color="#FFFFFF"
          component="div"
          sx={{ flexGrow: 1 }}
          variant="h6"
        >
          Requester Home
        </Typography>
        {/*
        <Button
          onClick={userData}
          variant="contained"
        >
          UserInfo
        </Button>
        */}
        <Button
          color="secondary"
          onClick={async () => {
            logout({
              returnTo: `${window.location.origin}/?org_id=${organization}`,
            });
          }}
          variant="contained"
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default RequestToolbar;
