import { useCallback, useContext, useEffect, useState } from "react";

import axios from "axios";
import { Box, FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import { Assignment, DepartureBoard, PendingActions } from "@mui/icons-material";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";

import AuthContext from "./AuthContext";
import CollectorModal from "./CollectorModal";
import CollectorToolbar from "./CollectorToolbar";
import RequestList from "./RequestList";
import { convertToFromUTC } from "./tools";

function decomposeRequests(data) {
  return {
    Available: data.filter((x) => {
      return !x.Concurrent && (x.Status === "Pending" && x.CreatedAt <= convertToFromUTC(new Date()))
    }),
    Accepted: data.filter((x) => {
      return !x.Concurrent && (x.Status === "In Process" || x.Status === "Arrived")
    }),
    Concurrent: data.filter((x) => x.Concurrent),
    Canceled: data.filter((x) => {
      return !x.Concurrent && (x.Status === "Canceled") && x.CreatedAt > convertToFromUTC(new Date().setDate(new Date().getDate() - 2))
    }),
    Upcoming: data.filter((x) => {
      return !x.Concurrent && (x.Status === "Pending" && x.CreatedAt > convertToFromUTC(new Date()))
    }),
    Completed: data.filter((x) => {
      return !x.Concurrent && (x.Status === "Complete" || x.Status === "No Show")
    }),
  };
}

const emptyRequests = {
  Available: [],
  Accepted: [],
  Upcoming: [],
};

async function readCollectorRequests(token, isLoggedIn, allHistory) {
  try {
    let { data, status } = await axios.get(
      allHistory ? "https://ahc.wolfeone.com/api/collectors/requests" : `https://ahc.wolfeone.com/api/collectors/requests?from=${convertToFromUTC(new Date().setDate(new Date().getDate() - 7))}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    let { data: concurrentData, status: concurrentStatus } = await axios.get(
      "https://ahc.wolfeone.com/api/collectors/requests?concurrent=true",
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    concurrentData = concurrentData.map((x) => {
      return { Concurrent: true, ...x };
    });
    return status > 299 || concurrentStatus > 299
      ? emptyRequests
      : decomposeRequests(data.concat(concurrentData));
  } catch (e) {
    if (isLoggedIn) {
      console.error(e);
      toast.error("Error reading request data");
    }
    return emptyRequests;
  }
}

async function getAcceptingRequests(token, isLoggedIn) {
  if (!token) {
    return {};
  }
  try {
    const { data } = await axios.get(
      "https://ahc.wolfeone.com/api/collectors",
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (e) {
    if (isLoggedIn) {
      console.error(e);
      toast.error("Error reading accepting requests");
    }
    return {};
  }
}

async function getIsBusy(token, isLoggedIn) {
  if (!token) {
    return {};
  }
  try {
    const { data } = await axios.get(
      "https://ahc.wolfeone.com/api/collectors",
      {
        headers: { authorization: `Bearer ${token}` },
      }
    );
    return data;
  } catch (e) {
    if (isLoggedIn) {
      console.error(e);
      toast.error("Error reading accepting requests");
    }
    return {};
  }
}

async function writeAcceptingRequests(token, acceptingRequests, isLoggedIn) {
  if (!token) {
    return {};
  }
  try {
    await axios.put(
      "https://ahc.wolfeone.com/api/collectors",
      { Active: acceptingRequests },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    if (isLoggedIn) {
      console.error(e);
      toast.error("Error writing accepting requests");
    }
  }

  return {};
}

// async function writeIsBusy(token, isBusy, isLoggedIn) {
//   if (!token) {
//     return {};
//   }
//   try {
//     await axios.put(
//       "https://ahc.wolfeone.com/api/collectors",
//       { Busy: isBusy },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           authorization: `Bearer ${token}`,
//         },
//       }
//     );
//   } catch (e) {
//     if (isLoggedIn) {
//       console.error(e);
//       toast.error("Error writing accepting requests");
//     }
//   }

//   return {};
// }

function CollectorHome() {
  const [isHomeLoading, setIsHomeLoading] = useState(true);
  const [acceptingRequests, setAcceptingRequests] = useState(false);
  // const [isBusy, setIsBusy] = useState(false);
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const [availableRequests, setAvailableRequests] = useState([]);
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  const [concurrentRequests, setConcurrentRequests] = useState([]);
  const [canceledRequests, setCanceledRequests] = useState([]);
  const [upcomingRequests, setUpcomingRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);
  const [trackable, setTrackable] = useState(false);
  const [useAllHistory, setUseAllHistory] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [requestDetail, setRequestDetail] = useState({});
  const queryClient = useQueryClient();

  const { accessToken, isLoggedIn, userType } = useContext(AuthContext);

  const { data: collectorRequestData, isLoading: collectorRequestIsLoading } =
    useQuery(
      ["collectorRequests", accessToken, useAllHistory],
      ({ queryKey }) => {
        const [, token, allHistory] = queryKey;
        console.debug("fetching collector requests", new Date());
        return token
          ? readCollectorRequests(token, isLoggedIn, allHistory)
          : emptyRequests;
      },
      {
        refetchInterval: 10000,
      }
    );

  const { data: acceptingRequestsData, isLoading: acceptingRequestsIsLoading } =
    useQuery(["acceptingRequests", accessToken], ({ queryKey }) => {
      const [, token] = queryKey;
      return getAcceptingRequests(token, isLoggedIn);
    });

  const { data: isBusyData, isLoading: isBusyIsLoading } = useQuery(
    ["isBusy", accessToken],
    ({ queryKey }) => {
      const [, token] = queryKey;
      return getIsBusy(token, isLoggedIn);
    }
  );

  // this "query" actually just posts a keep-alive
  useQuery(
    ["acceptingRequestsKeepAlive", accessToken],
    async ({ queryKey }) => {
      console.log("Keeping Alive");
      const [, token] = queryKey;
      await writeAcceptingRequests(token, true, isLoggedIn);
    },
    {
      refetchInterval: 10000,
      enabled: acceptingRequests,
    }
  );

  const requestMutation = useMutation(
    async ({ token, checked }) => {
      await writeAcceptingRequests(token, checked, isLoggedIn);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["acceptingRequests"]);
        queryClient.invalidateQueries(["collectorRequests"]);
      },
    }
  );
  // const isBusyMutation = useMutation(
  //   async ({ token, checked }) => {
  //     await writeIsBusy(token, checked, isLoggedIn);
  //   },
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["isBusy"]);
  //     },
  //   }
  // );
  const handleAcceptingRequestsChange = (e) => {
    requestMutation.mutate({ token: accessToken, checked: e.target.checked });
  };
  // const handleIsBusyChange = (e) => {
  //   isBusyMutation.mutate({ token: accessToken, checked: e.target.checked });
  // };

  const { data: collectorPosition } = useQuery(
    ["geolocation", accessToken],
    async ({ queryKey }) => {
      const [, token] = queryKey;
      if (token) {
        try {
          const position = await new Promise((resolve, reject) =>
            navigator.geolocation.getCurrentPosition(resolve, reject)
          );

          try {
            await axios.put(
              "https://ahc.wolfeone.com/api/collectors",
              {
                Latitude: position.coords.latitude,
                Longitude: position.coords.longitude,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${token}`,
                },
              }
            );
          } catch (error) {
            if (isLoggedIn) {
              console.error(error);
              toast.error("Error writing geolocation");
            }
          }
        } catch (error) {
          console.error(error);
          toast.error(
            "Error reading your location. Please update your location settings in the browser."
          );
        }
      }
      return null;
    },
    {
      refetchInterval: 10000,
      enabled: trackable,
    }
  );

  const { data: trackableData, isLoading: trackableLoading } = useQuery(
    ["trackable", accessToken],
    async ({ queryKey }) => {
      const [, token] = queryKey;
      if (!token) {
        return trackable;
      }
      try {
        const { data } = await axios.get(
          "https://ahc.wolfeone.com/api/collectors",
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        return data.Trackable;
      } catch (e) {
        if (isLoggedIn) {
          console.error(e);
          toast.error("Error reading tracking status");
        }
        return trackable;
      }
    }
  );
  const trackableMutation = useMutation(
    async ({ token, checked }) => {
      try {
        await axios.put(
          "https://ahc.wolfeone.com/api/collectors",
          { Trackable: checked },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (e) {
        if (isLoggedIn) {
          console.error(e);
          toast.error("Error updating tracking status");
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trackable"]);
        queryClient.invalidateQueries(["geolocation"]);
      },
    }
  );

  const requestDetailCallback = useCallback(
    (data) => {
      console.log("callback data: ", data);
      setAvailableRequests(data?.Available);
      setAcceptedRequests(data?.Accepted);
      setConcurrentRequests(data?.Concurrent);
      setCanceledRequests(data?.Canceled);
      setUpcomingRequests(data?.Upcoming);
      setCompletedRequests(data?.Completed);
      if (!isEmpty(requestDetail)) {
        const findfun = (x) =>
          x?.CollectionRequestGUID === requestDetail?.CollectionRequestGUID;
        const newRequest =
          data?.Available.find(findfun) ??
          data?.Accepted.find(findfun) ??
          data?.Concurrent.find(findfun) ??
          data?.Canceled.find(findfun) ??
          data?.Upcoming.find(findfun) ??
          data?.Completed.find(findfun);
        if (newRequest) {
          setRequestDetail(newRequest);
        }
      }
    },
    [requestDetail]
  );

  useEffect(() => {
    if (accessToken) {
      trackableLoading || setTrackable(trackableData);
      // if (!isEmpty(isBusyData)) {
      //   isBusyIsLoading || setIsBusy(isBusyData?.Busy);
      // }
      if (!isEmpty(acceptingRequestsData)) {
        acceptingRequestsIsLoading ||
          setAcceptingRequests(acceptingRequestsData?.Active);
      }
      if (collectorRequestData) {
        collectorRequestIsLoading ||
          requestDetailCallback(collectorRequestData);
      }
      setIsHomeLoading(collectorRequestIsLoading || acceptingRequestsIsLoading);
      console.log("collectorPosition: ", collectorPosition);
    }
  }, [
    audience,
    accessToken,
    collectorRequestData,
    acceptingRequestsData,
    isBusyData,
    collectorRequestIsLoading,
    acceptingRequestsIsLoading,
    isBusyIsLoading,
    trackableData,
    trackableLoading,
    collectorPosition,
    requestDetailCallback,
  ]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CollectorToolbar settingsVisible={settingsVisible} setSettingsVisible={setSettingsVisible} />
      <Stack style={{ margin: "20px" }} spacing={2}>
        {settingsVisible &&
        <FormGroup>
          <Stack direction="column">
            {userType !== "supervisor" && <FormControlLabel
              control={
                <Switch
                  onChange={handleAcceptingRequestsChange}
                  checked={acceptingRequests}
                />
              }
              label={`Status: ${acceptingRequests ? "Accepting Requests" : "Inactive"}`}
              labelPlacement={"start"}
              style={{ marginLeft: "auto" }}
            />}
            {/* <FormControlLabel
              control={
                <Switch onChange={handleIsBusyChange} checked={isBusy} />
              }
              label={`Busy: ${isBusy ? "YES" : "NO"}`}
              labelPlacement={"start"}
              style={{ marginLeft: "auto" }}
            /> */}
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) =>
                    trackableMutation.mutate({
                      token: accessToken,
                      checked: e.target.checked,
                    })
                  }
                  checked={trackable}
                />
              }
              label={"Track My Location"}
              labelPlacement={"start"}
              style={{ marginLeft: "auto" }}
            />
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setUseAllHistory(e.target.checked)}
                  checked={useAllHistory}
                />
              }
              label={"Show All History"}
              labelPlacement={"start"}
              style={{ marginLeft: "auto" }}
            />
          </Stack>
        </FormGroup>}
        <CollectorModal
          accessToken={accessToken}
          open={modalOpen}
          request={requestDetail}
          setOpen={setModalOpen}
        />
        <RequestList
          heading="Available Requests"
          iconFn={() => <Assignment />}
          isLoading={isHomeLoading}
          requests={availableRequests}
          setOpen={setModalOpen}
          setRequestDetail={setRequestDetail}
        />
        <RequestList
          heading="Accepted Requests"
          iconFn={() => <DepartureBoard />}
          isLoading={isHomeLoading}
          requests={acceptedRequests}
          setOpen={setModalOpen}
          setRequestDetail={setRequestDetail}
        />
        {concurrentRequests?.length > 0 &&
        <RequestList
          defaultCollapsed={true}
          heading="Concurrent Requests"
          iconFn={() => <PendingActions />}
          isLoading={isHomeLoading}
          requests={concurrentRequests}
          setOpen={userType === "supervisor" ? setModalOpen : () => {}}
          setRequestDetail={
            userType === "supervisor" ? setRequestDetail : () => {}
          }
        />}
        {canceledRequests?.length > 0 &&
        <RequestList
          defaultCollapsed={true}
          heading="Canceled Requests"
          iconFn={() => <PendingActions />}
          isLoading={isHomeLoading}
          requests={canceledRequests}
          setOpen={setModalOpen}
          setRequestDetail={setRequestDetail}
        />}
        <RequestList
          heading="Upcoming Requests"
          iconFn={() => <PendingActions />}
          isLoading={isHomeLoading}
          requests={upcomingRequests}
          setOpen={setModalOpen}
          setRequestDetail={setRequestDetail}
        />
        <RequestList
          defaultCollapsed={true}
          heading="Completed Requests"
          iconFn={() => <PendingActions />}
          isLoading={isHomeLoading}
          requests={completedRequests}
          setOpen={setModalOpen}
          setRequestDetail={setRequestDetail}
        />
      </Stack>
    </Box>
  );
}

export default CollectorHome;
